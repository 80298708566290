<template>
  <div class="mx-4 mt-8">
    <div>
      <div class=" pb-3 mb-6 border-b-2 border-black">
        <p class="text-xl">Payment history</p>
      </div>
      <div class="mt-4 pb-2 border-b-2 border-black" v-for="(payment, index) in paymentHistory" :key="index">
        <!-- <div>October 2021 - September 2021</div> -->
        <div>{{ dateFormat(payment.created_at) }} - {{ dateFormat(payment.ends_at) }}</div>
        <div class="capitalize">{{ payment.stripe_status == 'paid' ? 'Payment Recieved' : payment.stripe_status }}</div>
        <!-- <div class="">Free</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import API from "../../../api/http";
import { mapGetters } from "vuex";

export default {
  name: "VotingHistory",
  data() {
    return {
      votes: [],
      loading: true,
      paymentHistory: [],
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({ user: 'User/user' })
  },
  methods: {
    init() {
      API.get("get-payment-history").then((response) => {
        // this.loading = false;
        console.log(response.payment_history);
        this.paymentHistory = response.payment_history
      });
    },
    dateFormat(date) {
      date = new Date(date);
      return date.toLocaleString('default', { month: 'long' }) + " " + date.getFullYear();
    }
  },
};
</script>
